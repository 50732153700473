

//
//	Ozara / Components / Headline
//


#{ $c-headline } {
	$c: &;

	width: 100%;
	margin-bottom: lines(1, f1);
	color: get-color( white );
	display: block;

	&_text {
		margin-bottom: -25%;
		display: inline-block;
		white-space: pre-wrap;
		line-height: 1.25; vertical-align: top;
		overflow: hidden;

		> div {
			display: inline-block;

			// overflow: hidden;
		}
	}


}


@include breakpoint( large ) {
	.c-headline {
		// margin-left: cols-fluid(1);
	}
}

@include breakpoint( small ) {
	.c-headline {
		// margin-left: 0;
	}
}