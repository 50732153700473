

//
//	Ozara / Components / Card
//


#{ $c-card } {

	//	Variables
	$box-shadow: 0 4px 12px 0 fade-out( get-color( grey ), 0.8 );
	$box-shadow-med: rgba(0,0,0,0.1) 0 4px 8px 0;
	$box-shadow-high: 0 4px 16px 0 fade-out( get-color( base ), 0.85 ), 0 16px 36px 0 fade-out( get-color( grey ), 0.9 );

	position: relative; z-index: 10;
	display: block;
	border-radius: 2px;
	background-color: get-color( white );
	box-shadow: $box-shadow-med;
	@include clearfix;
	transform: translate3d( 0, 0, 0 );
	transition:
		border-color 0.4s 0.1s,
		background-color 0.4s 0.1s,
		box-shadow 0.6s,
		transform 0.4s get-easing( custom );

	//	Elements

	&_content {
		padding: lines(3) gutters(1); box-sizing: border-box;
		display: flex; flex-flow: column wrap;

		&_card_price {
			width: lines(5); height: lines(5);
			display: flex;
			justify-content: center; align-items: center;
			position: absolute; top: lines( -1.5 ); right: lines( -1.5 ); z-index: 20;
			color: get-color( white );
			background: get-color( brand );
			border-radius: 50%;
			box-shadow: 0 1px 2px 0 rgba(43,45,66,0.20), 0 4px 12px 0 rgba(43,45,66,0.10);
			// transform: scale(0.75);
		}

		&_card_edit {
			cursor: pointer;
			width: lines(5); height: lines(5);
			display: flex;
			justify-content: center; align-items: center;
			position: absolute; top: lines( -1.5 ); right: lines( -1.5 ); z-index: 20;
			color: get-color( white );
			background: get-color( grey );
			border-radius: 50%;
			box-shadow: 0 1px 2px 0 rgba(43,45,66,0.20), 0 4px 12px 0 rgba(43,45,66,0.10);
			// transform: scale(0.75);

			transition: transform 0.2s, box-shadow 0.2s;

			&:hover {
				// opacity: 0.5;
				box-shadow: 0 2px 8px 0 rgba(43,45,66,0.20), 0 12px 32px 0 rgba(43,45,66,0.10);
				transform: translateY(-1px);
			}
		}
	}

	&_image {
		height: segments(1);
		background-color: get-color( brand );
	}

	&_divider {
		width: 100%;
		margin: 0 gutters(-1); padding: 0 gutters(1);
		border-top: 1px solid get-color( grey );
		opacity: 0.15;
	}

	&_icon {
		position: absolute; bottom: lines(3); right: lines(4);
		color: get-color( grey );

		#{ $c-icon } {
			transition: transform 0.3s, color 0.3s;
		}
	}

	&_actions {
		padding: 0 gutters(1);
		margin-bottom: lines(-2);
		position: relative; z-index: 10;
		// position: absolute; top: lines(3.5); right: gutters(1); z-index: 10;

		.-actions-large & {
			margin-bottom: lines(-3);
		}
	}
		&_actions_item {
			// margin-top: lines(0.5);
		}


	//	Sizes

	//	Formats

	&.-format-p1 {
		#{ $c-card }_content {
			padding: lines(1) gutters(0.5) lines(1);
		}
	}

	&.-format-p2 {
		#{ $c-card }_content {
			padding-top: lines(4);
		}
	}

	&.-format-padded {
		#{ $c-card }_content {
			padding: lines(5) gutters(1) lines(3);
		}
	}

	&.-format-expand {
		padding-right: cols(2);
		position: relative;
		cursor: pointer;

		&:after {
			content: '';
			display: block;
			position: absolute; bottom: 0; left: gutters(1); right: gutters(1);
			border-bottom: 1px solid fade-out( get-color( base ), 0.9 );
		}

		#{ $c-card } {
			&-expand_content {
				padding-bottom: lines(1);
				overflow: hidden;
			}
			&-expand_action {
				position: absolute; top: lines(3); right: lines(4);
			}
		}
	}

	&.-format-link {
		box-sizing: border-box;
		border-left: 4px solid get-color( grey );
		cursor: pointer;

		&:hover {
			border-left-color: get-color( brand );

			h6 {
				color: get-color( brand );
			}

			#{ $c-card }_icon #{ $c-icon } {
				color: get-color( brand );
				transform: translateX( lines(1) );
			}
		}
	}

	&.-format-icon-after {
		#{ $c-card }_content {
			padding-right: gutters(1.5);
			padding-bottom: lines(7);
		}
	}

	&.-format-stretch {
		height: 100%;

		#{ $c-card }_content {
			height: 100%;
		}
	}


	//	Styles

	&.-style-reveal {
		background-color: transparent;
		box-shadow: none;
		border: none;

		&:hover {
			background-color: get-color( white );
			transform: translate3d( 0, lines(-0.5), 0 );
			box-shadow: $box-shadow-high;
		}
	}

	&.-style-outline {
		background-color: transparent;
		box-shadow: none;
		border: 1px solid fade-out( get-color( base ), 0.9 );
	}

	&.-style-dark {
		color: get-color( white );
		background-color: get-color( base );
		box-shadow: none; z-index: 0;

		h1, h2, h3, h4, h5, h6 {
			color: inherit;
		}
	}


	//	States

	&.-state-disabled {
		background-color: fade-out( get-color( white ), 0.25 );

		.c-card_content {
			opacity: 0.5;
		}
	}

	//	Themes

	// 	Contexts


}

a#{ $c-card } {
	color: inherit;
}



/**
 * 	Media Queries
 *	--------------------------------------------------
 */


/** Breakpoint:	Large ----------------------- */

@include breakpoint( large ) {

}

/** Breakpoint:	Small ----------------------- */

@include breakpoint( small ) {

	#{ $c-card } {
		margin-left: gutters(-0.5);
		margin-right: gutters(-0.5);

		&.-format-expand {
			margin: 0 gutters(-1);
			padding-right: 0;
		}

		&.-format-icon-after {
			#{ $c-card }_icon {
				bottom: auto; top: lines(4);
			}
			#{ $c-card }_content {
				padding-right: gutters(3);
				padding-bottom: lines(2);
			}
		}
	}

}
