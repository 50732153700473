


//
//	FE / Views / Landing
//



.v-landing {

	section.intro {
		// min-width: cols(6);
		height: 65vh;
		margin-bottom: lines(-16);
		display: flex; flex: 0 0 auto; flex-flow: row wrap;
		position: relative;
		justify-content: center;

		.c-button {
			&:hover {
				color: get-color( brand-yellow );
			}
		}
	}
		&_logo {
			margin-bottom: auto;
		}

	&_3d {
		pointer-events: none;
		// background-color: #fff;

		&-canvas canvas {
			height: lines(100);
		}
	}

	.bg {
		width: 100%; height: 100%;
		position: absolute; top: 0; left: 0; z-index: 5;
		// opacity: 0.25;
		opacity: 0.75;

		&-video {
			width: 100%;
			position: absolute; top: lines(-12); left: 0; bottom: 0; z-index: 0;

			&:after {
				content: '';
				$bg-color: black;
				$dot-color: white;
				// $dot-color: lighten( get-color( base ), 10 );

				// Dimensions
				$dot-size: 1px;
				$dot-space: 16px;
				width: 100%; height: 100%;
				position: absolute; top: 0; left: 0; z-index: 5;
				background-image: radial-gradient( circle at 0.5px 0.5px, black 1px, transparent 0) ;
				background-size: 2px 2px;
				background-position: -19px;
			}

			&:before {
				content: '';
				width: 100%; height: lines(80);
				position: absolute; bottom: 0; left: 0; z-index: 10;
				background-image: linear-gradient( 0deg, black 0, transparent 100% );
			}

			video {
				width: auto; height: auto;
				min-width: 100%; min-height: 100%;
				position: absolute; top: 0; left: 50%; z-index: 0;
				transform: translate3d( -50%, 0, 0 );
				opacity: 0.5;
			}
		}
	}

}


/**
 * 	Media Queries
 *	--------------------------------------------------
 */


/** Breakpoint:	Large ----------------------- */

@include breakpoint( large ) {

	.v-landing {

	}

}


/** Breakpoint:	Small ----------------------- */

@include breakpoint( small ) {

	.v-landing {

		&_intro {
			width: auto;
		}

		// &_headline {
		// 	flex-basis: 100%;
		// }

		&_logo {
			margin-bottom: lines(6);
		}

		&_features {
			min-width: 0;
		}

	}

}