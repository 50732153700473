

//
//	Ozara / Base / Grid
//


%container {
	max-width: 96%;

	&.-span-wide {
		max-width: 62%;
	}
}
	.col {

	}


/**
 * 	Media Queries
 *	--------------------------------------------------
 */


/** Breakpoint:	Large ----------------------- */

@include breakpoint( large ) {

	// %container {
	// 	width: 80%;
	// }

}


/** Breakpoint:	Small ----------------------- */

@include breakpoint( small ) {

	%container {
		width: 100%;
		max-width: 100%;
	}

		.col {
			// width: percentage( 1 );
			// flex: 1 0 percentage( 1 );
			min-width: cols(2);

			//	Column Sizes	 ------------

			&.-span-single,
			&.-span-small,
			&.-span-quarter,
			&.-span-third,
			&.-span-half,
			&.-span-wide {
				width: percentage( 1 );
				flex: 1 0 percentage( 1 );
			}

		}
}