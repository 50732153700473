

//
//	WeProspect / UI / Icons
//



#{ $c-icon-group } {
	display: flex; flex-flow: row wrap; align-items: baseline;

	#{ $c-icon } {
		margin-right: gutters(0.5);
		align-self: center;
	}

	p {
		margin: 0; padding: 0;
	}

	&.-v-top {
		align-items: flex-start;
	}
}

#{ $c-icon } {
	width: lines(3); height: lines(3);
	max-width: 100%; max-height: 100%;
	position: relative;
	display: inline-flex; align-items: center; justify-content: center;
	vertical-align: top;

	//	Elements

	svg {
		width: 100%; height: 100%;
	}

	span {
		z-index: 1;
	}

	&_tag {
		min-width: lines(3); height: lines(3);
		padding: 0 lines(1); box-sizing: border-box;
		display: block; position: absolute; top: 0; right: 0; z-index: 10;
		font-size: 62.5%; line-height: lines(3); color: get-color( white ); font-weight: bold;
		background-color: get-color( brand );
		border-radius: lines(3);
		transform: translate( 50%, -25% );

		&.-type-info {
			background-color: get-color( grey );
		}

		&.-type-primary {
			background-color: get-color( brand );
		}
	}

	// 	Styles

	.-color-primary {
		fill: lighten( get-color( brand ), 15 );
	}

	.-color-secondary {
		fill: get-color( grey );
	}


	// 	Sizes

	&.-size-xxsmall {
		width: lines(1.5); height: lines(1.5);
	}

	&.-size-xsmall {
		width: lines(2); height: lines(2);
	}

	&.-size-small {
		width: lines(2.5); height: lines(2.5);
	}

	&.-size-medium {
		width: lines(4); height: lines(4);
	}

	&.-size-large {
		width: segments(1); height: segments(1);
	}

	// 	Formats

	&.-format-circle {
		padding: lines(2);
		flex: 0 0 auto;

		&:after {
			content: '';
			width: 100%; height: 100%;
			box-sizing: border-box;
			position: absolute; top: 50%; left: 50%;
			border: 2px solid currentColor; border-radius: 50%;
			transform: translate( -50%, -50% );
		}

		&.-size-xsmall {
			padding: lines(1.25);

			&:after {
				border-width: 1px;
			}
		}
		&.-size-xxsmall {
			padding: lines(0.75);

			&:after {
				border-width: 1px;
			}
		}

		&[class*="-bg-"] {
			background-color: transparent;

			&:before {
				content: '';
				width: 100%; height: 100%;
				box-sizing: border-box;
				position: absolute; top: 60%; left: 40%; z-index: 0;
				background-color: get-color( grey );
				border-radius: 50%;
				transform: translate( -50%, -50% );
			}
		}
	}

	// 	States

	&.-is-clickable {
		cursor: pointer;
	}

	&.-anim-spin {
		animation: spin 2s infinite;
	}

	//	Contexts

	p & {
		height: lines(3) !important;
		vertical-align: middle;
		// line-height: 1;

		&.-size-xsmall {
			height: lines(2) !important;
		}
	}

	#{ $c-button } & {
		margin: 0 lines( 1 );
	}

	#{ $c-button }.-size-small & {
		margin: 0 lines( 0.75 );
	}

	#{ $c-button }.-size-xsmall & {
		margin: 0 lines( 0.5 );
	}
	#{ $c-button }.-size-xxsmall & {
		margin: 0 lines( 0.25 );
	}

	#{ $c-button }.-format-circle & {
		margin: 0 auto;
	}

}


@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg);  }
}
