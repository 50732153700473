

//
//	Ozara / UI / Components / Nav
//


#{ $c-nav } {
	$c: &;

	//	Elements	 --------------------

	ul {
		margin: 0; padding: 0;
		display: flex; flex-flow: column wrap;
		list-style-type: none;
	}
		li {
			margin: 0; padding: 0;
			display: block;
			position: relative;
			font-family: $font-secondary;
			line-height: 1;

			a {
				color: currentColor;
				// padding-bottom: lines(0.5);
				// overflow: hidden;
				position: relative;
				transition: color 0.3s;

				span {
					@include font-size( default, 2, true );
					// padding-bottom: lines(1);
					margin-top: lines(0.5);
					position: relative; z-index: 10;
					display: inline-block;
					white-space: nowrap;
					background: linear-gradient( transparent calc(100% - 2px), currentColor 10px );
					background-repeat: no-repeat;
					background-size: 0 100%;
					transition: background-size 0.3s ease;
				}

				// &:after {
				// 	content: '';
				// 	width: 100%; height: 2px;
				// 	position: absolute; bottom: 0; left: 0; z-index: 0;
				// 	background-color: get-color( brand );
				// 	transform: translate3d(-102%, 0, 0);
				// 	transition: transform 0.3s ease;
				// 	opacity: 1;
				// }

				&.-is-active,
				&:hover {
					color: get-color( brand );
					span {
						background-size: 100% 100%;
					}
				}
			}
		}


	//	Styles	 ------------------------

	&.-style-secondary {

		ul li {
			font-family: $font-primary;
			font-weight: normal;
		}
	}

	//	States	 ------------------------

	//	Formats	 ------------------------

	&.-format-horizontal {
		width: 100%;
		display: flex;

		ul {
			width: 100%;
			flex-flow: row nowrap;

			// li > a {
			// 	&.-is-active,
			// 	&:hover {
			// 		color: get-color( base );
			// 	}
			// }

			li + li {
				margin-left: gutters(1);
			}
		}
	}

	//	Sizes	 ------------------------

	&.-size-medium {
		ul li a {
			span {
				@include font-size( f5, 2, true, 1.5 );
			}
		}
	}

	&.-size-large {
		ul li a {
			span {
				@include font-size( f4, 3, true, 1.5 );
			}
		}
	}

	//	Themes	 ------------------------

	.-t-dark & {

		a {
			color: get-color( white );
		}
	}
}


/**
 * 	Media Queries
 *	--------------------------------------------------
 */

/** Breakpoint:	Small ----------------------- */

@include breakpoint( small ) {

	#{ $c-nav } {
		$c: &;

		//	Formats	 ------------------------

		&.-format-horizontal {
			width: auto;
			margin: 0 gutters(-1);
			// display: flex;
			overflow: hidden; overflow-x: auto;
			-webkit-overflow-scrolling: touch;

			ul {
				width: auto;
				padding: 0 gutters(1);
			}
		}
	}
}
