

//
//	WeProspect / Base / Defaults
//


/*
 *	Fonts
 *	--------------------------------------------------
 */

// @include font-face( 'nd', '../fonts/nd-medium', bold, normal );
@include font-face( 'r', '../fonts/reader-regular-web', normal, normal );
@include font-face( 'r', '../fonts/reader-bold-web', bold, normal );



@keyframes spin {
	100% {
		transform: rotate( 360deg );
	}
}

svg {
	position: relative;
}