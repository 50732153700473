

//
//	Ozara / Settings
//


/*
 *	Colours
 *	--------------------------------------------------
 */

$colors: (
	//	Base Palette
	white:					#ffffff,
	grey: 					#BFC3CE,
		grey-light: 		#F6F6FA,

	// 	Brand Palette
	base: 					#000000,
		base-med: 			#1F2A4B,
		base-light: 		desaturate( lighten( #1F2A4B, 20 ), 20 ),
	brand: 					#1A41B1,
	brand-yellow: 			#FEE133,

	//	Status Palette
	error: 					#ff0000
);

$theme-colors: (
	base-light: ( default: get( base-light, $colors ))
);

$background-color: get( base, $colors );


/*
 *	Typography
 *	--------------------------------------------------
 */

$type-scale: (
	/*	title: 		( size (px), lines(baseline), margins(baseline), cap-height(ratio), map to tag ) */
	default: 	( px: 16, lines: 3, margin: 2, cap: 0.75, map-to: h5 ),

	f1:			( px: 80, lines: 10, margin: 3, cap: 0.75, map-to: h1 ),
	f2: 		( px: 52, lines: 8, margin: 3, cap: 0.8, map-to: h2 ),
	f3: 		( px: 36, lines: 6, margin: 3, cap: 0.8, map-to: h3 ),
	f4: 		( px: 22, lines: 4, margin: 3, cap: 0.75, map-to: h4 ),
	f5: 		( px: 18, lines: 4, margin: 3, cap: 0.75 ),

	f6: 		( px: 14, lines: 3, margin: 3, cap: 0.6, map-to: h6 ),
	f7: 		( px: 12, lines: 2 )
);

$type-settings: (
	line-height:			8,	// px
	segment-size:			8,	// lines

	field-input-size: 		f4,
	field-label-size: 		f7
);

//	Font Vars
$font-primary:			"r", Arial, sans-serif;
$font-secondary:		"r", Georgia, serif;

$text-color: 			get( grey, $colors );
$link-color:			get( brand, $colors ) !default;
$link-color-hover:		get( brand-yellow, $colors ) !default;
$button-color:			get( brand, $colors ) !default;


/*
 *	Grid
 *	--------------------------------------------------
 */

$grid: (
	column-width:			84, /* The column-width of your grid in pixels */
	gutter-width:			32, /* The gutter-width of your grid in pixels */

	breakpoint-columns: (
		default:	9,
		small: 		6,
		large: 		12
	)
);


// $easings: (
// 	ease-custom:		cubic-bezier(0.4, 0, 0, 1)
// );


/*
 *	Breakpoints
 *	--------------------------------------------------
 */

// $breakpoints: (
// 	/*	title: 		( direction: mq, width: pixel, scale: 16+/- ) */
// 	default:	( direction: max, width: 1280, scale: 0 ),
// 	small: 		( direction: max, width: 768, scale: 0 ),
// 	large: 		( direction: min, width: 1281, scale: 0 )
// );