/**
 *  Get from Sass Map
 *  
 *  Gets the key from a map. Recursively access maps 
 *  using dot notation ( 'alpha.bravo.charlie' ).
 *  
	.foo {
		color: get( brand-color );
		background-color: get( 'first.second.color', $some-map );
	}
 *
 * 	@param 	{ String } [ $key ] - The key of the desired value
 * 	@param 	{ Map } [ $map=$frontier ] - The map to search
 * 	
 * 	@return {undefined} Returns the assoicated variable
 *  --------------------------------------------------
 */
/**
 *  Extend Map with Deep Merge
 *
 * 	@param 	{ Map } $object - first map
 * 	@param 	{ ArgList } $objects - other maps
 * 	@param 	{ Bool } $deep - recursive mode
 * 	@return { Map }
 *  --------------------------------------------------
 */
/*
 *	Colours
 *	--------------------------------------------------
 */
/*
 *	Typography
 *	--------------------------------------------------
 */
/*
 *	Grid
 *	--------------------------------------------------
 */
/*
 *	Breakpoints
 *	--------------------------------------------------
 */
/*
 *	Vars: Main
 *	--------------------------------------------------
 */
/**
 *  Get from Sass Map
 *  
 *  Gets the key from a map. Recursively access maps 
 *  using dot notation ( 'alpha.bravo.charlie' ).
 *  
	.foo {
		color: get( brand-color );
		background-color: get( 'first.second.color', $some-map );
	}
 *
 * 	@param 	{ String } [ $key ] - The key of the desired value
 * 	@param 	{ Map } [ $map=$frontier ] - The map to search
 * 	
 * 	@return {undefined} Returns the assoicated variable
 *  --------------------------------------------------
 */
/**
 *  Extend Map with Deep Merge
 *
 * 	@param 	{ Map } $object - first map
 * 	@param 	{ ArgList } $objects - other maps
 * 	@param 	{ Bool } $deep - recursive mode
 * 	@return { Map }
 *  --------------------------------------------------
 */
/*
 *	Colours
 *	--------------------------------------------------
 */
/*
 *	Typography
 *	--------------------------------------------------
 */
/*
 *	Grid
 *	--------------------------------------------------
 */
/*
 *	Breakpoints
 *	--------------------------------------------------
 */
/*
 *	Easings
 *	--------------------------------------------------
 */
/*
 *	Import: Frontier
 *	--------------------------------------------------
 */
/*
 *	Colours
 *	--------------------------------------------------
 */
/*
 *	Font sizing
 *	--------------------------------------------------
 */
/*
 *	Baseline spacing
 *	--------------------------------------------------
 */
/*
 *	EM -> PX Conversion
 *	--------------------------------------------------
 */
/*
 *	Unitless
 *	--------------------------------------------------
 */
/*
 *	Columns
 *	--------------------------------------------------
 */
/*
 *	Gutter size
 *	--------------------------------------------------
 */
/*
 *	Segment spacing
 *	--------------------------------------------------
 */
/*
 *	Easings
 *	--------------------------------------------------
 */
/*
 *	Breakpoint Builder
 *	--------------------------------------------------
 */
/*
 *	Mixin: Clearfix
 *	--------------------------------------------------
 */
/*
 *	Font Size
 *	--------------------------------------------------
 */
/**
 * @mixin font-face
 * @description Add custom font-face
 *
 * @param {string} [$font-family] The name of the font-family
 * @param {string} [$file-path] The location of the font files relative to the outputed CSS (.eot, .woff, .ttf, and .svg expected)
 * @param {string} [$weight=normal] The weight of the font
 * @param {string} [$style=normal] The style of the font
 *
 * @example
	.foo {
	  @include font-face('UI Font', '../fonts/ui-font/ui-font', bold, italic);
	}
 */
/*
 *	Core
 *	--------------------------------------------------
 */
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

/*
 *	HTML5 Display Definitions
 *	--------------------------------------------------
 */
article, aside, details, figcaption, figure, footer,
header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

/*
 *	Anchors
 *	--------------------------------------------------
 */
a:active, a:hover {
  outline: 0;
}

/*
 *	Typography
 *	--------------------------------------------------
 */
abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/*
 *	Embedded Content
 *	--------------------------------------------------
 */
img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

/*
 *	Grouped Content
 *	--------------------------------------------------
 */
figure {
  margin: 1em 40px;
}

hr {
  height: 0;
  box-sizing: content-box;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/*
 *	Forms
 *	--------------------------------------------------
 */
button, input, optgroup, select, textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

button {
  overflow: visible;
  -webkit-appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

button, select {
  text-transform: none;
}

html input[type="button"],
input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

/*
 *	Tables
 *	--------------------------------------------------
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

.-t-dark {
  color: #BFC3CE;
}

.-t-dark h1, .-t-dark h2, .-t-dark h3, .-t-dark h4, .-t-dark h5, .-t-dark h6 {
  color: #ffffff;
}

.-color-grey {
  color: #BFC3CE;
}

.-bg-grey {
  background-color: #BFC3CE;
}

.-color-grey-med {
  color: #666;
}

.-bg-grey-med {
  background-color: #666;
}

.-color-brand {
  color: #1A41B1;
}

.-bg-brand {
  background-color: #1A41B1;
}

.-color-base-light {
  color: #525d7e;
}

.-bg-base-light {
  background-color: #525d7e;
}

html {
  font-family: "r", Arial, sans-serif;
}

body {
  background-color: #000000;
}

/*
 *	Container
 *	--------------------------------------------------
 */
.container {
  width: 67.25em;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1em;
  box-sizing: border-box;
  position: relative;
  z-index: 10;
}

.container:before, .container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

.-span-half.container {
  width: 34.625em;
}

.-span-narrow.container {
  width: 52.75em;
}

.-span-wide.container {
  width: 80%;
}

.-span-full.container {
  width: 100%;
}

.-span-article.container {
  width: 54.75em;
}

/*
 *	Structure
 *	--------------------------------------------------
 */
.row {
  position: relative;
  display: flex;
  flex-flow: row wrap;
}

.row.-margin-none {
  margin-bottom: 0;
}

.row.-pos-center {
  justify-content: center;
}

.row.-masonry {
  max-height: 50vh;
  flex-flow: column wrap;
}

.col {
  width: 25%;
  max-width: 100%;
  padding: 0 1em;
  flex: 1 1 0%;
  box-sizing: border-box;
}

.col[class*="-span-"] {
  flex: 0 0 auto;
}

.col.-span-single {
  width: 11.11111111%;
}

.col.-span-small {
  width: 22.22222222%;
}

.col.-span-third {
  width: 33.33333333%;
}

.col.-span-quarter {
  width: 25%;
}

.col.-span-half {
  width: 50%;
}

.col.-span-twothirds {
  width: 66.66666667%;
}

.col.-span-wide {
  width: 75%;
}

.col.-span-full {
  width: 100%;
}

.col.-max-third {
  max-width: 21.75em;
}

.col.-max-half {
  max-width: 32.625em;
}

.col.-max-twothirds {
  max-width: 43.5em;
}

.col.-max-wide {
  max-width: 48.9375em;
}

.col.-max-full {
  max-width: 65.25em;
}

.col.-min-2 {
  min-width: 14.5em;
}

.col.-min-3 {
  min-width: 21.75em;
}

.col.-min-4 {
  min-width: 29em;
}

.col.-min-5 {
  min-width: 36.25em;
}

.col.-min-6 {
  min-width: 43.5em;
}

.col.-max-1 {
  max-width: 7.25em;
}

.col.-max-2 {
  max-width: 14.5em;
}

.col.-max-3 {
  max-width: 21.75em;
}

.col.-max-4 {
  max-width: 29em;
}

.col.-max-5 {
  max-width: 36.25em;
}

.col.-max-6 {
  max-width: 43.5em;
}

.col.-max-7 {
  max-width: 50.75em;
}

.col.-max-8 {
  max-width: 58em;
}

.col.-max-9 {
  max-width: 65.25em;
}

.col.-max-10 {
  max-width: 72.5em;
}

.col.-max-11 {
  max-width: 79.75em;
}

.col.-max-12 {
  max-width: 87em;
}

.col.-pos-right {
  margin-left: auto;
}

.col.-pos-center {
  justify-content: center;
}

.col.-margin-none {
  margin-bottom: 0;
}

.col.-flush {
  padding: 0 0.0625em 0.0625em 0;
}

.col .row {
  margin-left: -1em;
  margin-right: -1em;
}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */
@media (max-width: 48em) {
  .container {
    width: 45.5em;
  }
  .-span-narrow.container {
    width: 31em;
  }
  .-span-half.container {
    width: 23.75em;
  }
}

@media (min-width: 80.0625em) {
  .container {
    width: 89em;
  }
  .-span-narrow.container {
    width: 74.5em;
  }
  .-span-half.container {
    width: 45.5em;
  }
}

/** Breakpoint:	Large ----------------------- */
@media (min-width: 80.0625em) {
  .col.-offset-1 {
    margin-left: 8.33333333%;
  }
}

/** Breakpoint:	Small ----------------------- */
@media (max-width: 48em) {
  .-span-article.container {
    width: 45.5em;
  }
  .-span-max-onsmall.container {
    width: 100%;
    padding: 0;
  }
  .-span-max-onsmall.container .col {
    padding: 0;
  }
  .col.-offset-1 {
    margin-left: 0;
  }
}

/*
 *	Main
 *	--------------------------------------------------
 */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #BFC3CE;
}

.default, h5, p {
  font-size: 1em;
  line-height: 1.5em;
  position: relative;
  margin: 0.375em 0 -0.5em;
  padding-bottom: 1.125em;
}

.default.-m1, h5.-m1, p.-m1 {
  padding-bottom: 0.625em;
}

.default.-m2, h5.-m2, p.-m2 {
  padding-bottom: 1.125em;
}

.default.-m3, h5.-m3, p.-m3 {
  padding-bottom: 1.625em;
}

.default.-m0, h5.-m0, p.-m0 {
  padding-bottom: 0.125em;
}

.default.-m0:after, h5.-m0:after, p.-m0:after {
  height: 0;
}

.alpha, h1 {
  font-size: 3.375em;
  line-height: 1.18518519em;
  position: relative;
  margin: 0.07407407em 0 -0.14814815em;
  padding-bottom: 0.37037037em;
}

.alpha.-m1, h1.-m1 {
  padding-bottom: 0.22222222em;
}

.alpha.-m2, h1.-m2 {
  padding-bottom: 0.37037037em;
}

.alpha.-m3, h1.-m3 {
  padding-bottom: 0.51851852em;
}

.alpha.-m0, h1.-m0 {
  padding-bottom: 0.07407407em;
}

.alpha.-m0:after, h1.-m0:after {
  height: 0;
}

.beta, h2 {
  font-size: 3em;
  line-height: 1em;
  position: relative;
  margin: 0.10416667em 0 -0.16666667em;
  padding-bottom: 0.39583333em;
}

.beta.-m1, h2.-m1 {
  padding-bottom: 0.22916667em;
}

.beta.-m2, h2.-m2 {
  padding-bottom: 0.39583333em;
}

.beta.-m3, h2.-m3 {
  padding-bottom: 0.5625em;
}

.beta.-m0, h2.-m0 {
  padding-bottom: 0.0625em;
}

.beta.-m0:after, h2.-m0:after {
  height: 0;
}

.gamma, h3 {
  font-size: 2.25em;
  line-height: 1.33333333em;
  position: relative;
  margin: 0.08333333em 0 -0.22222222em;
  padding-bottom: 0.58333333em;
}

.gamma.-m1, h3.-m1 {
  padding-bottom: 0.36111111em;
}

.gamma.-m2, h3.-m2 {
  padding-bottom: 0.58333333em;
}

.gamma.-m3, h3.-m3 {
  padding-bottom: 0.80555556em;
}

.gamma.-m0, h3.-m0 {
  padding-bottom: 0.13888889em;
}

.gamma.-m0:after, h3.-m0:after {
  height: 0;
}

.delta, h4, p.-lead {
  font-size: 1.5em;
  line-height: 1.33333333em;
  position: relative;
  margin: 0.29166667em 0 -0.33333333em;
  padding-bottom: 1.04166667em;
}

.delta.-m1, h4.-m1, p.-m1.-lead {
  padding-bottom: 0.375em;
}

.delta.-m2, h4.-m2, p.-m2.-lead {
  padding-bottom: 0.70833333em;
}

.delta.-m3, h4.-m3, p.-m3.-lead {
  padding-bottom: 1.04166667em;
}

.delta.-m0, h4.-m0, p.-m0.-lead {
  padding-bottom: 0.04166667em;
}

.delta.-m0:after, h4.-m0:after, p.-m0.-lead:after {
  height: 0;
}

.epsilon, p small, p.-small {
  font-size: 1.125em;
  line-height: 1.33333333em;
  position: relative;
  margin: 0.27777778em 0 -0.44444444em;
  padding-bottom: 1.5em;
}

.epsilon.-m1, p small.-m1, p.-m1.-small {
  padding-bottom: 0.61111111em;
}

.epsilon.-m2, p small.-m2, p.-m2.-small {
  padding-bottom: 1.05555556em;
}

.epsilon.-m3, p small.-m3, p.-m3.-small {
  padding-bottom: 1.5em;
}

.epsilon.-m0, p small.-m0, p.-m0.-small {
  padding-bottom: 0.16666667em;
}

.epsilon.-m0:after, p small.-m0:after, p.-m0.-small:after {
  height: 0;
}

.zeta {
  font-size: 0.875em;
  line-height: 1.71428571em;
  position: relative;
  margin: 0em 0 -0.57142857em;
  padding-bottom: 2.28571429em;
}

.zeta.-m1 {
  padding-bottom: 1.14285714em;
}

.zeta.-m2 {
  padding-bottom: 1.71428571em;
}

.zeta.-m3 {
  padding-bottom: 2.28571429em;
}

.zeta.-m0 {
  padding-bottom: 0.57142857em;
}

.zeta.-m0:after {
  height: 0;
}

.eta, h6 {
  font-size: 0.75em;
  line-height: 1.33333333em;
  position: relative;
  margin: 0.33333333em 0 -0.66666667em;
  padding-bottom: 2.33333333em;
}

.eta.-m1, h6.-m1 {
  padding-bottom: 1em;
}

.eta.-m2, h6.-m2 {
  padding-bottom: 1.66666667em;
}

.eta.-m3, h6.-m3 {
  padding-bottom: 2.33333333em;
}

.eta.-m0, h6.-m0 {
  padding-bottom: 0.33333333em;
}

.eta.-m0:after, h6.-m0:after {
  height: 0;
}

.theta {
  font-size: 0.6875em;
  line-height: 1.45454545em;
  position: relative;
  margin: 0.45454545em 0 -0.72727273em;
  padding-bottom: 2.45454545em;
}

.theta.-m1 {
  padding-bottom: 1em;
}

.theta.-m2 {
  padding-bottom: 1.72727273em;
}

.theta.-m3 {
  padding-bottom: 2.45454545em;
}

.theta.-m0 {
  padding-bottom: 0.27272727em;
}

.theta.-m0:after {
  height: 0;
}

.iota {
  font-size: 0.5625em;
  line-height: 1.33333333em;
  position: relative;
  margin: 1.22222222em 0 -0.88888889em;
  padding-bottom: 2.33333333em;
}

.iota.-m1 {
  padding-bottom: 0.55555556em;
}

.iota.-m2 {
  padding-bottom: 1.44444444em;
}

.iota.-m3 {
  padding-bottom: 2.33333333em;
}

.iota.-m0 {
  padding-bottom: -0.33333333em;
}

.iota.-m0:after {
  height: 0;
}

.kappa {
  font-size: 0.5em;
  line-height: 1.5em;
  position: relative;
  margin: 0.5em 0 -1em;
  padding-bottom: 2.5em;
}

.kappa.-m1 {
  padding-bottom: 1.5em;
}

.kappa.-m2 {
  padding-bottom: 2.5em;
}

.kappa.-m3 {
  padding-bottom: 3.5em;
}

.kappa.-m0 {
  padding-bottom: 0.5em;
}

.kappa.-m0:after {
  height: 0;
}

.f1, h1 {
  font-size: 5em;
  line-height: 1em;
  position: relative;
  margin: 0.025em 0 -0.1em;
  padding-bottom: 0.375em;
}

.f1.-m1, h1.-m1 {
  padding-bottom: 0.175em;
}

.f1.-m2, h1.-m2 {
  padding-bottom: 0.275em;
}

.f1.-m3, h1.-m3 {
  padding-bottom: 0.375em;
}

.f1.-m0, h1.-m0 {
  padding-bottom: 0.075em;
}

.f1.-m0:after, h1.-m0:after {
  height: 0;
}

.f2, h2 {
  font-size: 3.25em;
  line-height: 1.23076923em;
  position: relative;
  margin: 0.05769231em 0 -0.15384615em;
  padding-bottom: 0.55769231em;
}

.f2.-m1, h2.-m1 {
  padding-bottom: 0.25em;
}

.f2.-m2, h2.-m2 {
  padding-bottom: 0.40384615em;
}

.f2.-m3, h2.-m3 {
  padding-bottom: 0.55769231em;
}

.f2.-m0, h2.-m0 {
  padding-bottom: 0.09615385em;
}

.f2.-m0:after, h2.-m0:after {
  height: 0;
}

.f3, h3 {
  font-size: 2.25em;
  line-height: 1.33333333em;
  position: relative;
  margin: 0.05555556em 0 -0.22222222em;
  padding-bottom: 0.83333333em;
}

.f3.-m1, h3.-m1 {
  padding-bottom: 0.38888889em;
}

.f3.-m2, h3.-m2 {
  padding-bottom: 0.61111111em;
}

.f3.-m3, h3.-m3 {
  padding-bottom: 0.83333333em;
}

.f3.-m0, h3.-m0 {
  padding-bottom: 0.16666667em;
}

.f3.-m0:after, h3.-m0:after {
  height: 0;
}

.f4, h4 {
  font-size: 1.375em;
  line-height: 1.45454545em;
  position: relative;
  margin: 0em 0 -0.36363636em;
  padding-bottom: 1.45454545em;
}

.f4.-m1, h4.-m1 {
  padding-bottom: 0.72727273em;
}

.f4.-m2, h4.-m2 {
  padding-bottom: 1.09090909em;
}

.f4.-m3, h4.-m3 {
  padding-bottom: 1.45454545em;
}

.f4.-m0, h4.-m0 {
  padding-bottom: 0.36363636em;
}

.f4.-m0:after, h4.-m0:after {
  height: 0;
}

.f5 {
  font-size: 1.125em;
  line-height: 1.77777778em;
  position: relative;
  margin: 0.05555556em 0 -0.44444444em;
  padding-bottom: 1.72222222em;
}

.f5.-m1 {
  padding-bottom: 0.83333333em;
}

.f5.-m2 {
  padding-bottom: 1.27777778em;
}

.f5.-m3 {
  padding-bottom: 1.72222222em;
}

.f5.-m0 {
  padding-bottom: 0.38888889em;
}

.f5.-m0:after {
  height: 0;
}

.f6, h6 {
  font-size: 0.875em;
  line-height: 1.71428571em;
  position: relative;
  margin: 0em 0 -0.57142857em;
  padding-bottom: 2.28571429em;
}

.f6.-m1, h6.-m1 {
  padding-bottom: 1.14285714em;
}

.f6.-m2, h6.-m2 {
  padding-bottom: 1.71428571em;
}

.f6.-m3, h6.-m3 {
  padding-bottom: 2.28571429em;
}

.f6.-m0, h6.-m0 {
  padding-bottom: 0.57142857em;
}

.f6.-m0:after, h6.-m0:after {
  height: 0;
}

.f7 {
  font-size: 0.75em;
  line-height: 1.33333333em;
  position: relative;
  margin: 0.33333333em 0 -0.66666667em;
  padding-bottom: 2.33333333em;
}

.f7.-m1 {
  padding-bottom: 1em;
}

.f7.-m2 {
  padding-bottom: 1.66666667em;
}

.f7.-m3 {
  padding-bottom: 2.33333333em;
}

.f7.-m0 {
  padding-bottom: 0.33333333em;
}

.f7.-m0:after {
  height: 0;
}

/*
 *	Headings
 *	--------------------------------------------------
 */
h1, h2, h3, h4, h5, h6 {
  font-family: "r", Georgia, serif;
  font-weight: normal;
  color: #000000;
  text-rendering: optimizelegibility;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-size: 0.625em;
  line-height: 0.8em;
  position: relative;
  margin: 0.9em 0 -0.8em;
  padding-bottom: 2.3em;
}

h1 small.-m1, h2 small.-m1, h3 small.-m1, h4 small.-m1, h5 small.-m1, h6 small.-m1 {
  padding-bottom: 0.7em;
}

h1 small.-m2, h2 small.-m2, h3 small.-m2, h4 small.-m2, h5 small.-m2, h6 small.-m2 {
  padding-bottom: 1.5em;
}

h1 small.-m3, h2 small.-m3, h3 small.-m3, h4 small.-m3, h5 small.-m3, h6 small.-m3 {
  padding-bottom: 2.3em;
}

h1 small.-m0, h2 small.-m0, h3 small.-m0, h4 small.-m0, h5 small.-m0, h6 small.-m0 {
  padding-bottom: -0.1em;
}

h1 small.-m0:after, h2 small.-m0:after, h3 small.-m0:after, h4 small.-m0:after, h5 small.-m0:after, h6 small.-m0:after {
  height: 0;
}

/*
 *	Paragraphs
 *	--------------------------------------------------
 */
p.-lead {
  color: #000000;
}

p.-intro {
  font-size: 1.125em;
  line-height: 1.55555556em;
  position: relative;
  margin: 0.38888889em 0 -0.44444444em;
  padding-bottom: 1.38888889em;
  font-weight: 100;
}

p.-intro.-m1 {
  padding-bottom: 0.5em;
}

p.-intro.-m2 {
  padding-bottom: 0.94444444em;
}

p.-intro.-m3 {
  padding-bottom: 1.38888889em;
}

p.-intro.-m0 {
  padding-bottom: 0.05555556em;
}

p.-intro.-m0:after {
  height: 0;
}

blockquote {
  margin: 1.5em 7.25em;
  position: relative;
}

blockquote:before {
  content: '';
  width: 0.375em;
  height: 100%;
  position: absolute;
  top: 0;
  left: -2em;
  display: block;
  background-color: #ddd;
}

blockquote p {
  margin: 0;
  padding: 0;
  font-style: italic;
}

/*
 *	Anchors
 *	--------------------------------------------------
 */
a {
  color: #1A41B1;
  display: inline-block;
  position: relative;
  text-decoration: none;
}

a.-is-hover, a:hover, a:focus, a:active {
  color: #FEE133;
  text-decoration: none;
}

a.-is-visited {
  color: #FEE133;
}

/*
 *	Lists
 *	--------------------------------------------------
 */
ul.-unstyled, ol.-unstyled {
  max-width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.-unstyled li:before, ol.-unstyled li:before {
  content: '';
}

ul, ol {
  max-width: 58em;
  margin: 0 0 1em;
  padding-left: 2.5em;
  box-sizing: border-box;
}

/*
 *	Styles
 *	--------------------------------------------------
 */
.-font-primary {
  font-family: "r", Arial, sans-serif;
}

.-font-secondary {
  font-family: "r", Georgia, serif;
}

.-f-light {
  font-weight: 100;
}

.-f-regular {
  font-weight: normal;
}

.-f-medium {
  font-weight: 500;
}

.-f-bold {
  font-weight: 700;
}

.-f-black {
  font-weight: 900;
}

.-f-underline {
  border-bottom: 1px solid currentColor;
}

.-f-italic {
  font-style: italic;
}

.-f-caps {
  text-transform: uppercase;
}

.-f-lower {
  text-transform: lowercase;
}

.-f-border {
  border-bottom: 2px solid currentColor;
}

.-align-center {
  text-align: center;
  justify-content: center;
}

.-align-left {
  text-align: left;
}

.-align-right {
  text-align: right;
}

/*
 * 	Media Queries
 *	--------------------------------------------------
 */
@media (max-width: 80em) {
  body {
    font-size: 1em;
  }
}

@media (max-width: 48em) {
  body {
    font-size: 1em;
  }
}

@media (min-width: 80.0625em) {
  body {
    font-size: 1em;
  }
}

img {
  max-width: 100%;
  font-style: italic;
  vertical-align: middle;
}

.svg {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.svg.-large {
  height: 4em;
}

svg {
  max-width: 100%;
  max-height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

table {
  width: 100%;
}

th,
td {
  padding: 0.5em 0;
  text-align: left;
}

input,
select,
textarea {
  margin: 0;
  padding: 0 1em;
  box-sizing: border-box;
  font-size: 1.375em;
  color: currentColor;
  font-weight: normal;
  line-height: 3.27272727em;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  resize: none;
  -webkit-appearance: none;
}

.c-field_value {
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  display: block;
  box-sizing: border-box;
  background-color: transparent;
  border: 2px solid rgba(170, 173, 199, 0.25);
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(43, 45, 66, 0.06), 0px 1px 4px rgba(43, 45, 66, 0.1);
  overflow: visible;
  transition: background-color 0.3s, border-color 0.3s;
}

.-t-dark .c-field_value {
  border-bottom-color: rgba(191, 195, 206, 0.1);
}

input,
select,
textarea {
  width: 100%;
  outline: 0;
  resize: none;
  -webkit-appearance: none;
  box-shadow: none;
}

input::placeholder,
select::placeholder,
textarea::placeholder {
  font-weight: 300;
  color: #BFC3CE;
  opacity: 1;
}

.-sl1 {
  margin-bottom: 0.5em;
}

.-sl2 {
  margin-bottom: 1em;
}

.-sl3 {
  margin-bottom: 1.5em;
}

.-sl4 {
  margin-bottom: 2em;
}

.-sl5 {
  margin-bottom: 2.5em;
}

.-sl6 {
  margin-bottom: 3em;
}

.-ss1 {
  margin-bottom: 4em;
}

.-ss2 {
  margin-bottom: 8em;
}

.-ss3 {
  margin-bottom: 12em;
}

.-s1,
.-spacer-1 {
  margin-bottom: 1em;
}

.-s2,
.-spacer-2 {
  margin-bottom: 2em;
}

.-s3,
.-spacer-3 {
  margin-bottom: 3em;
}

.-s4,
.-spacer-4 {
  margin-bottom: 4em;
}

.-s5 .-spacer-5 {
  margin-bottom: 5em;
}

.-s6,
.-spacer-6 {
  margin-bottom: 6em;
}

.-st1 {
  padding-top: 0.5em;
}

.-st2 {
  padding-top: 1em;
}

.-st3 {
  padding-top: 1.5em;
}

.-st4 {
  padding-top: 2em;
}

.-st5 {
  padding-top: 2.5em;
}

.-st6 {
  padding-top: 3em;
}

.-ml1 {
  margin-left: 2em;
}

.-ml2 {
  margin-left: 4em;
}

.-mr1 {
  margin-right: 2em;
}

.-mr2 {
  margin-right: 4em;
}

.-p0 {
  padding: 0;
}

.-pb0 {
  padding-bottom: 0 !important;
}

.-pr0 {
  padding-right: 0 !important;
}

.-pr1 {
  padding-right: 0.5em;
}

.-pr2 {
  padding-right: 1em;
}

.-pr2.col {
  padding-right: 2em;
}

.-pr3 {
  padding-right: 1.5em;
}

.-pr3.col {
  padding-right: 2.5em;
}

.-ob3 {
  margin-bottom: -1.5em;
}

.-ob2 {
  margin-bottom: -1em;
}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */
/** Breakpoint:	Small ----------------------- */
@media (max-width: 48em) {
  .-sl2 {
    margin-bottom: 0.5em;
  }
  .-sl3 {
    margin-bottom: 0.75em;
  }
}

/*
 *	Breakpoint Display
 *	--------------------------------------------------
 */
.-helper-show-baseline {
  background: linear-gradient(rgba(128, 128, 128, 0.1) 0.5em, transparent 0.5em);
  background-size: 65.25em 1em;
  background-position: center top;
}

@media (max-width: 80em) {
  body:after {
    content: "default";
    display: none;
  }
}

@media (max-width: 48em) {
  body:after {
    content: "small";
    display: none;
  }
}

@media (min-width: 80.0625em) {
  body:after {
    content: "large";
    display: none;
  }
}

.-hide-small {
  display: none;
}

.-hide-large {
  display: inherit;
}

/*
 * 	Media Queries
 *	--------------------------------------------------
 */
/** Breakpoint:	Small ----------------------- */
/*
 *	Fonts
 *	--------------------------------------------------
 */
@font-face {
  font-family: "r";
  src: url("../fonts/reader-regular-web.woff2") format("woff2"), url("../fonts/reader-regular-web.woff") format("woff"), url("../fonts/reader-regular-web.eot") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "r";
  src: url("../fonts/reader-bold-web.woff2") format("woff2"), url("../fonts/reader-bold-web.woff") format("woff"), url("../fonts/reader-bold-web.eot") format("embedded-opentype");
  font-weight: bold;
  font-style: normal;
  font-display: auto;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

svg {
  position: relative;
}

/*
 *	Headings
 *	--------------------------------------------------
 */
h1 {
  font-weight: bold;
  letter-spacing: -3px;
}

h2 {
  font-weight: bold;
  letter-spacing: -1px;
}

h3, h4, h5, h6 {
  font-family: "r", Arial, sans-serif;
  font-weight: bold;
  color: inherit;
  color: #000000;
}

h6 {
  font-weight: bold;
  color: #1A41B1;
}

ul, ol {
  list-style-type: none;
  padding-left: 1.5em;
}

ul li, ol li {
  font-weight: normal;
}

.-style-gradient {
  background: linear-gradient(80deg, #000000 40%, #1A41B1 130%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/**
 * 	Breakpoints
 *	--------------------------------------------------
 */
/** Breakpoint:	Desktop ----------------------- */
/** Breakpoint:	small ----------------------- */
@media (max-width: 48em) {
  h1, h2, h3 {
    letter-spacing: -1px;
  }
  .f1, h1 {
    font-size: 3.25em;
    line-height: 1.23076923em;
    position: relative;
    margin: 0.05769231em 0 -0.15384615em;
    padding-bottom: 0.55769231em;
  }
  .f1.-m1, h1.-m1 {
    padding-bottom: 0.25em;
  }
  .f1.-m2, h1.-m2 {
    padding-bottom: 0.40384615em;
  }
  .f1.-m3, h1.-m3 {
    padding-bottom: 0.55769231em;
  }
  .f1.-m0, h1.-m0 {
    padding-bottom: 0.09615385em;
  }
  .f1.-m0:after, h1.-m0:after {
    height: 0;
  }
  .f2, h2 {
    font-size: 2.25em;
    line-height: 1.33333333em;
    position: relative;
    margin: 0.05555556em 0 -0.22222222em;
    padding-bottom: 0.83333333em;
  }
  .f2.-m1, h2.-m1 {
    padding-bottom: 0.38888889em;
  }
  .f2.-m2, h2.-m2 {
    padding-bottom: 0.61111111em;
  }
  .f2.-m3, h2.-m3 {
    padding-bottom: 0.83333333em;
  }
  .f2.-m0, h2.-m0 {
    padding-bottom: 0.16666667em;
  }
  .f2.-m0:after, h2.-m0:after {
    height: 0;
  }
  .f4, h4 {
    font-size: 1.125em;
    line-height: 1.77777778em;
    position: relative;
    margin: 0.05555556em 0 -0.44444444em;
    padding-bottom: 1.72222222em;
  }
  .f4.-m1, h4.-m1 {
    padding-bottom: 0.83333333em;
  }
  .f4.-m2, h4.-m2 {
    padding-bottom: 1.27777778em;
  }
  .f4.-m3, h4.-m3 {
    padding-bottom: 1.72222222em;
  }
  .f4.-m0, h4.-m0 {
    padding-bottom: 0.38888889em;
  }
  .f4.-m0:after, h4.-m0:after {
    height: 0;
  }
}

.container {
  max-width: 96%;
}

.-span-wide.container {
  max-width: 62%;
}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */
/** Breakpoint:	Large ----------------------- */
/** Breakpoint:	Small ----------------------- */
@media (max-width: 48em) {
  .container {
    width: 100%;
    max-width: 100%;
  }
  .col {
    min-width: 14.5em;
  }
  .col.-span-single, .col.-span-small, .col.-span-quarter, .col.-span-third, .col.-span-half, .col.-span-wide {
    width: 100%;
    flex: 1 0 100%;
  }
}

.c-button {
  margin: 0;
  padding: 1em 1.5em;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /*font-family: $font-secondary;*/
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: #1F2A4B;
  border: 0;
  border-radius: 0;
  box-shadow: 0 1px 2px 0 rgba(43, 45, 66, 0.2), 0 4px 12px 0 rgba(43, 45, 66, 0.1);
  transition: transform 0.2s ease-out, color 0.3s, background-color 0.3s, opacity 0.2s, box-shadow 0.2s;
}

.c-button_text {
  line-height: 1.5em;
  margin: 0 0.5em;
  white-space: nowrap;
}

.c-button:before {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  border: 4px solid rgba(0, 0, 0, 0.1);
}

.c-button:focus {
  outline: 0;
}

.c-button:hover {
  color: #ffffff;
  background-color: #1d49c7;
}

.c-button:hover, .c-button:visited, .c-button:active {
  color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(43, 45, 66, 0.2), 0 12px 32px 0 rgba(43, 45, 66, 0.1);
}

.c-button.-style-none {
  padding: 0;
  color: inherit;
  background-color: transparent;
  box-shadow: none;
}

.c-button.-style-outline {
  position: relative;
  color: currentColor;
  background: transparent;
  box-shadow: none;
}

.c-button.-style-outline:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid currentColor;
  opacity: 0.1;
}

.c-button.-style-outline:hover {
  color: #1A41B1;
}

.c-button.-style-error {
  background-color: #ff0000;
}

.c-button.-style-error:hover {
  background-color: #ff1a1a;
}

.c-button.-size-xsmall {
  padding: 0.25em 0.5em;
}

.c-button.-size-xsmall .c-button_text {
  font-size: 0.75em;
  line-height: 2em;
  margin: 0 0.33333333em;
}

.c-button.-size-xsmall.-style-outline {
  font-weight: normal;
}

.c-button.-size-small {
  padding: 0.5em 0.75em;
}

.c-button.-size-small .c-button_text {
  font-size: 0.875em;
  line-height: 1.5em;
  margin: 0 0.375em;
}

.c-button.-size-large {
  padding: 1.25em 2em;
  border-radius: 3em;
}

.c-button.-size-large .c-button_text {
  font-size: 1.125em;
}

.c-button.-format-block {
  width: 100%;
  flex: 1 1 auto;
  justify-content: space-between;
}

.c-button.-format-link {
  padding-left: 0;
  padding-right: 0;
  color: #000000;
  background-color: transparent;
  box-shadow: none;
}

.c-button.-format-link .c-button_text {
  font-weight: normal;
  position: relative;
  overflow: hidden;
}

.c-button.-format-link .c-button_text:after {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: #1A41B1;
  transform: translate3d(-102%, 0, 0);
  transition: transform 0.3s ease;
  opacity: 1;
}

.c-button.-format-link:hover {
  color: #1A41B1;
}

.c-button.-format-link:hover .c-button_text:after {
  transform: translate3d(0, 0, 0);
}

.c-button.-format-link.-size-xsmall .c-button_text {
  margin: 0 0.66666667em;
}

.c-button.-format-link span:first-child {
  margin-left: 0 !important;
}

.c-button.-format-circle {
  width: 3.5em;
  padding: 1em 0.5em;
}

.c-button.-format-circle.-style-outline:after {
  border-width: 3px;
  border-radius: 50%;
}

.c-button.-format-circle.-size-small {
  width: 2.5em;
  padding: 0.75em 0;
}

.c-button.-pos-right {
  margin-left: auto !important;
}

.c-button.-is-disabled {
  pointer-events: none !important;
  background-color: #BFC3CE;
}

.c-button.-is-disabled .c-button_text {
  opacity: 0.25;
}

.c-button-group .c-button + .c-button {
  margin-left: 1em;
}

.c-button-group .c-button + .c-button.-size-small {
  margin-left: 0.5em;
}

.c-button-group .c-button + .c-button.-size-xsmall {
  margin-left: 0.5em;
}

.c-button-group.-format-grouped .c-button {
  border-radius: 0;
}

.c-button-group.-format-grouped .c-button:after {
  border-radius: 0;
  border-right-width: 0;
}

.c-button-group.-format-grouped .c-button + .c-button {
  margin-left: 0;
}

.c-button-group.-format-grouped .c-button:first-child, .c-button-group.-format-grouped .c-button:first-child:after {
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
}

.c-button-group.-format-grouped .c-button:last-child, .c-button-group.-format-grouped .c-button:last-child:after {
  border-top-right-radius: 2em;
  border-bottom-right-radius: 2em;
}

.c-button-group.-format-grouped .c-button:last-child:after {
  border-right-width: 1px;
}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */
@media (hover: hover) {
  .c-button:hover {
    transform: translateY(-1px);
  }
}

/** Breakpoint:	Large ----------------------- */
/** Breakpoint:	Small ----------------------- */
@media (max-width: 48em) {
  .c-button-group .c-button + .c-button {
    margin-left: 0;
  }
  .c-button-group .c-button + .c-button.-size-small {
    margin-left: 0;
  }
  .c-button-group .c-button + .c-button.-size-xsmall {
    margin-left: 0;
  }
}

.c-card {
  position: relative;
  z-index: 10;
  display: block;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 8px 0;
  transform: translate3d(0, 0, 0);
  transition: border-color 0.4s 0.1s, background-color 0.4s 0.1s, box-shadow 0.6s, transform 0.4s;
}

.c-card:before, .c-card:after {
  content: " ";
  display: table;
}

.c-card:after {
  clear: both;
}

.c-card_content {
  padding: 1.5em 2em;
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
}

.c-card_content_card_price {
  width: 2.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -0.75em;
  right: -0.75em;
  z-index: 20;
  color: #ffffff;
  background: #1A41B1;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(43, 45, 66, 0.2), 0 4px 12px 0 rgba(43, 45, 66, 0.1);
}

.c-card_content_card_edit {
  cursor: pointer;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -0.75em;
  right: -0.75em;
  z-index: 20;
  color: #ffffff;
  background: #BFC3CE;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(43, 45, 66, 0.2), 0 4px 12px 0 rgba(43, 45, 66, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.c-card_content_card_edit:hover {
  box-shadow: 0 2px 8px 0 rgba(43, 45, 66, 0.2), 0 12px 32px 0 rgba(43, 45, 66, 0.1);
  transform: translateY(-1px);
}

.c-card_image {
  height: 4em;
  background-color: #1A41B1;
}

.c-card_divider {
  width: 100%;
  margin: 0 -2em;
  padding: 0 2em;
  border-top: 1px solid #BFC3CE;
  opacity: 0.15;
}

.c-card_icon {
  position: absolute;
  bottom: 1.5em;
  right: 2em;
  color: #BFC3CE;
}

.c-card_icon .c-icon {
  transition: transform 0.3s, color 0.3s;
}

.c-card_actions {
  padding: 0 2em;
  margin-bottom: -1em;
  position: relative;
  z-index: 10;
}

.-actions-large .c-card_actions {
  margin-bottom: -1.5em;
}

.c-card.-format-p1 .c-card_content {
  padding: 0.5em 1em 0.5em;
}

.c-card.-format-p2 .c-card_content {
  padding-top: 2em;
}

.c-card.-format-padded .c-card_content {
  padding: 2.5em 2em 1.5em;
}

.c-card.-format-expand {
  padding-right: 14.5em;
  position: relative;
  cursor: pointer;
}

.c-card.-format-expand:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 2em;
  right: 2em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.c-card.-format-expand .c-card-expand_content {
  padding-bottom: 0.5em;
  overflow: hidden;
}

.c-card.-format-expand .c-card-expand_action {
  position: absolute;
  top: 1.5em;
  right: 2em;
}

.c-card.-format-link {
  box-sizing: border-box;
  border-left: 4px solid #BFC3CE;
  cursor: pointer;
}

.c-card.-format-link:hover {
  border-left-color: #1A41B1;
}

.c-card.-format-link:hover h6 {
  color: #1A41B1;
}

.c-card.-format-link:hover .c-card_icon .c-icon {
  color: #1A41B1;
  transform: translateX(0.5em);
}

.c-card.-format-icon-after .c-card_content {
  padding-right: 3em;
  padding-bottom: 3.5em;
}

.c-card.-format-stretch {
  height: 100%;
}

.c-card.-format-stretch .c-card_content {
  height: 100%;
}

.c-card.-style-reveal {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.c-card.-style-reveal:hover {
  background-color: #ffffff;
  transform: translate3d(0, -0.25em, 0);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15), 0 16px 36px 0 rgba(191, 195, 206, 0.1);
}

.c-card.-style-outline {
  background-color: transparent;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.c-card.-style-dark {
  color: #ffffff;
  background-color: #000000;
  box-shadow: none;
  z-index: 0;
}

.c-card.-style-dark h1, .c-card.-style-dark h2, .c-card.-style-dark h3, .c-card.-style-dark h4, .c-card.-style-dark h5, .c-card.-style-dark h6 {
  color: inherit;
}

.c-card.-state-disabled {
  background-color: rgba(255, 255, 255, 0.75);
}

.c-card.-state-disabled .c-card_content {
  opacity: 0.5;
}

a.c-card {
  color: inherit;
}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */
/** Breakpoint:	Large ----------------------- */
/** Breakpoint:	Small ----------------------- */
@media (max-width: 48em) {
  .c-card {
    margin-left: -1em;
    margin-right: -1em;
  }
  .c-card.-format-expand {
    margin: 0 -2em;
    padding-right: 0;
  }
  .c-card.-format-icon-after .c-card_icon {
    bottom: auto;
    top: 2em;
  }
  .c-card.-format-icon-after .c-card_content {
    padding-right: 6em;
    padding-bottom: 1em;
  }
}

.c-field {
  margin-bottom: 1em;
  display: block;
  cursor: pointer;
}

.c-field_icon {
  width: 3.5em;
  height: 3.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.5;
  pointer-events: none;
  transition: opacity 0.2s;
}

.c-field_label {
  font-size: 0.75em;
  line-height: 1.33333333em;
  position: relative;
  margin: 0.33333333em 0 -0.66666667em;
  padding-bottom: 0.33333333em;
  width: 100%;
  display: block;
  position: relative;
  z-index: 10;
  text-align: left;
  color: currentColor;
  -webkit-touch-callout: none;
  user-select: none;
  pointer-events: none;
  transform: translateY(0);
  transition: color 0.3s, opacity 0.3s, transform 0.3s;
}

.c-field_label .c-icon {
  color: currentColor;
  opacity: 0;
  transition: opacity 0.3s;
}

.c-field_value {
  display: flex;
  flex: 1 1 0%;
}

.c-field_message {
  padding: 0 0.5em;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 50;
  text-align: left;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(43, 45, 66, 0.2), 0 4px 12px 0 rgba(43, 45, 66, 0.1);
  transform: translateY(100%);
  opacity: 0;
  pointer-events: none;
  transition: background-color 0.3s, color 0.3s, opacity 0.3s, transform 0.3s;
}

.c-field_message:before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  top: -12px;
  left: 0.5em;
  border: 6px solid transparent;
  border-bottom-color: #ffffff;
}

.c-field_message p {
  font-size: 0.75em;
  line-height: 2em;
  position: relative;
  margin: 0em 0 -0.66666667em;
  padding-bottom: 0.66666667em;
  font-weight: bold;
}

.c-field_message p.-m1 {
  padding-bottom: 1.33333333em;
}

.c-field_message p.-m2 {
  padding-bottom: 2em;
}

.c-field_message p.-m3 {
  padding-bottom: 2.66666667em;
}

.c-field_message p.-m0 {
  padding-bottom: 0.66666667em;
}

.c-field_message p.-m0:after {
  height: 0;
}

.c-field_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.c-field:hover .c-field_icon {
  opacity: 1;
}

.c-field.-is-empty .c-field_label {
  opacity: 1;
  transform: translateY(0);
}

.c-field.-is-empty select {
  font-weight: 300;
  color: rgba(191, 195, 206, 0.75);
}

.c-field.-is-inactive .c-field_label {
  opacity: 0.5;
}

.c-field.-is-valid .c-field_label .c-icon {
  color: #1A41B1;
  opacity: 1;
}

.c-field.-is-error {
  color: #1A41B1;
}

.c-field.-is-error input,
.c-field.-is-error select,
.c-field.-is-error textarea {
  border-bottom: 1px solid rgba(26, 65, 177, 0.25);
}

.c-field.-is-error .c-field_label .c-icon {
  color: #1A41B1;
  opacity: 1;
}

.c-field.-is-error .c-field_message {
  color: #ffffff;
  background-color: #1A41B1;
  transform: translateY(75%);
  opacity: 1;
}

.c-field.-is-error .c-field_message:before {
  border-bottom-color: #1A41B1;
}

.c-field-text input[disabled] {
  cursor: not-allowed !important;
}

.c-field-text.-is-disabled {
  color: #BFC3CE;
}

.c-footer {
  width: 100%;
  padding: 8em 0 4em;
  margin-top: auto;
  background: linear-gradient(90deg, #ffffff 14px, transparent 1%) center, linear-gradient(#ffffff 14px, transparent 1%) center, #F6F6FA;
  background-size: 16px 16px;
  background-position: 0 0;
}

.c-footer h6 {
  color: #BFC3CE;
}

.c-header {
  width: 90%;
  height: 6em;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: flex-end;
  color: #ffffff;
  background-color: #1A41B1;
}

.c-header .c-nav {
  margin-top: 0.5em;
}

.c-header .c-nav a:hover {
  color: #FEE133;
}

.c-header .c-button:hover {
  color: #FEE133;
}

.c-header_logo {
  width: 10.25em;
  height: 2.5em;
  margin: 0 auto -0.5em;
  display: block;
  color: #ffffff;
}

.c-header_contact {
  width: auto;
  display: flex;
  align-items: flex-end;
  flex: 0 1 auto;
}

.c-header_contact ul li {
  padding: 0 0 0 2em;
  display: inline-block;
}

.c-header_contact .divider {
  margin: 0 0.25em;
  opacity: 0.5;
}

.c-header-nav {
  height: 0;
  position: fixed;
  top: 0;
  left: 5em;
  right: 5em;
  z-index: 10;
}

.c-header-nav .o-icon {
  width: 2em;
}

.c-header-nav .o-icon svg {
  stroke: #000000;
  stroke-width: 2px;
}

.c-header-nav_container {
  width: 100%;
  max-width: 108.75em;
  height: 0;
  position: relative;
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
}

.c-header-nav_bar {
  width: 7em;
  height: 100vh;
  padding: 11em 0;
  position: absolute;
  top: 0;
  left: -5em;
  z-index: 20;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.c-header-nav_bar .menu-tag {
  transform: rotate(-90deg) translateX(-100%);
  font-size: 14px;
  opacity: 0.5;
}

.c-header-nav_panel {
  width: 50%;
  min-width: 43.5em;
  height: 100vh;
  padding: 10em 0 0 4em;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-flow: column nowrap;
  overflow: auto;
  opacity: 0;
  pointer-events: none;
  transition: transform 0.5s ease, opacity 0.5s;
}

.c-header-nav_panel nav {
  margin-bottom: 5em;
  transition: transform 0.5s ease;
}

.c-header-nav_panel nav li {
  opacity: 0;
  transform: translate3d(0, 2em, 0);
  transition: transform 1s, opacity 1s;
  will-change: transform, opacity;
}

.c-header-nav_panel nav a {
  font-size: 3em;
  line-height: 1em;
  position: relative;
  margin: 0.10416667em 0 -0.16666667em;
  padding-bottom: 0.39583333em;
  position: relative;
  font-family: "r", Georgia, serif;
  color: #000000;
  overflow: hidden;
  transition: color 0.2s;
}

.c-header-nav_panel nav a.-m1 {
  padding-bottom: 0.22916667em;
}

.c-header-nav_panel nav a.-m2 {
  padding-bottom: 0.39583333em;
}

.c-header-nav_panel nav a.-m3 {
  padding-bottom: 0.5625em;
}

.c-header-nav_panel nav a.-m0 {
  padding-bottom: 0.0625em;
}

.c-header-nav_panel nav a.-m0:after {
  height: 0;
}

.c-header-nav_panel nav a span {
  position: relative;
  z-index: 10;
}

.c-header-nav_panel nav a:after {
  content: '';
  width: 100%;
  height: 0.5em;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 0;
  background-color: #1A41B1;
  transform: translate3d(-100%, -50%, 0);
  transition: transform 0.3s ease;
}

.c-header-nav_panel nav a.-is-active:after, .c-header-nav_panel nav a:hover:after {
  transform: translate3d(0, -50%, 0);
}

.-is-menu-open .c-header-nav_panel {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  pointer-events: all;
  transition: transform 0.75s, opacity 0.75s;
}

.-is-menu-open .c-header-nav_panel nav li {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.-is-menu-open .c-header-nav_panel nav li:nth-child(1) {
  transition-delay: 0s;
}

.-is-menu-open .c-header-nav_panel nav li:nth-child(2) {
  transition-delay: 0.1s;
}

.-is-menu-open .c-header-nav_panel nav li:nth-child(3) {
  transition-delay: 0.2s;
}

.-is-menu-open .c-header-nav_panel nav li:nth-child(4) {
  transition-delay: 0.3s;
}

.-is-menu-open .c-header-nav_panel nav li:nth-child(5) {
  transition-delay: 0.4s;
}

.-is-menu-open .c-header-nav_panel nav li:nth-child(6) {
  transition-delay: 0.5s;
}

.c-header-nav_panel_footer {
  margin: auto 0 5em;
}

.c-header-nav_overlay {
  width: 50%;
  height: 100vh;
  min-width: 43.5em;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
}

.-is-menu-open .c-header-nav_overlay {
  opacity: 1;
}

@media (min-width: 80.0625em) {
  .c-header-nav {
    left: 8em;
    right: 8em;
  }
  .c-header-nav_bar {
    width: 10em;
    left: -8em;
  }
}

.c-headline {
  width: 100%;
  margin-bottom: 0.1em;
  color: #ffffff;
  display: block;
}

.c-headline_text {
  margin-bottom: -25%;
  display: inline-block;
  white-space: pre-wrap;
  line-height: 1.25;
  vertical-align: top;
  overflow: hidden;
}

.c-headline_text > div {
  display: inline-block;
}

.c-icon-group {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
}

.c-icon-group .c-icon {
  margin-right: 1em;
  align-self: center;
}

.c-icon-group p {
  margin: 0;
  padding: 0;
}

.c-icon-group.-v-top {
  align-items: flex-start;
}

.c-icon {
  width: 1.5em;
  height: 1.5em;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
}

.c-icon svg {
  width: 100%;
  height: 100%;
}

.c-icon span {
  z-index: 1;
}

.c-icon_tag {
  min-width: 1.5em;
  height: 1.5em;
  padding: 0 0.5em;
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  font-size: 62.5%;
  line-height: 1.5em;
  color: #ffffff;
  font-weight: bold;
  background-color: #1A41B1;
  border-radius: 1.5em;
  transform: translate(50%, -25%);
}

.c-icon_tag.-type-info {
  background-color: #BFC3CE;
}

.c-icon_tag.-type-primary {
  background-color: #1A41B1;
}

.c-icon .-color-primary {
  fill: #3662e1;
}

.c-icon .-color-secondary {
  fill: #BFC3CE;
}

.c-icon.-size-xxsmall {
  width: 0.75em;
  height: 0.75em;
}

.c-icon.-size-xsmall {
  width: 1em;
  height: 1em;
}

.c-icon.-size-small {
  width: 1.25em;
  height: 1.25em;
}

.c-icon.-size-medium {
  width: 2em;
  height: 2em;
}

.c-icon.-size-large {
  width: 4em;
  height: 4em;
}

.c-icon.-format-circle {
  padding: 1em;
  flex: 0 0 auto;
}

.c-icon.-format-circle:after {
  content: '';
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  border: 2px solid currentColor;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.c-icon.-format-circle.-size-xsmall {
  padding: 0.625em;
}

.c-icon.-format-circle.-size-xsmall:after {
  border-width: 1px;
}

.c-icon.-format-circle.-size-xxsmall {
  padding: 0.375em;
}

.c-icon.-format-circle.-size-xxsmall:after {
  border-width: 1px;
}

.c-icon.-format-circle[class*="-bg-"] {
  background-color: transparent;
}

.c-icon.-format-circle[class*="-bg-"]:before {
  content: '';
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 60%;
  left: 40%;
  z-index: 0;
  background-color: #BFC3CE;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.c-icon.-is-clickable {
  cursor: pointer;
}

.c-icon.-anim-spin {
  animation: spin 2s infinite;
}

p .c-icon {
  height: 1.5em !important;
  vertical-align: middle;
}

p .c-icon.-size-xsmall {
  height: 1em !important;
}

.c-button .c-icon {
  margin: 0 0.5em;
}

.c-button.-size-small .c-icon {
  margin: 0 0.375em;
}

.c-button.-size-xsmall .c-icon {
  margin: 0 0.25em;
}

.c-button.-size-xxsmall .c-icon {
  margin: 0 0.125em;
}

.c-button.-format-circle .c-icon {
  margin: 0 auto;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.c-logo {
  margin: 0 auto;
  width: 10.25em;
}

.c-topgun-logo {
  margin: 0 auto;
  width: 20em;
  color: #ffffff;
}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */
/** Breakpoint:	Large ----------------------- */
/** Breakpoint:	Small ----------------------- */
.c-nav ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column wrap;
  list-style-type: none;
}

.c-nav li {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  font-family: "r", Georgia, serif;
  line-height: 1;
}

.c-nav li a {
  color: currentColor;
  position: relative;
  transition: color 0.3s;
}

.c-nav li a span {
  font-size: 1em;
  line-height: 1em;
  position: relative;
  margin: 0.625em 0 -0.5em;
  padding-bottom: 0.875em;
  margin-top: 0.25em;
  position: relative;
  z-index: 10;
  display: inline-block;
  white-space: nowrap;
  background: linear-gradient(transparent calc(100% - 2px), currentColor 10px);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.3s ease;
}

.c-nav li a span.-m1 {
  padding-bottom: 0.375em;
}

.c-nav li a span.-m2 {
  padding-bottom: 0.875em;
}

.c-nav li a span.-m3 {
  padding-bottom: 1.375em;
}

.c-nav li a span.-m0 {
  padding-bottom: -0.125em;
}

.c-nav li a span.-m0:after {
  height: 0;
}

.c-nav li a.-is-active, .c-nav li a:hover {
  color: #1A41B1;
}

.c-nav li a.-is-active span, .c-nav li a:hover span {
  background-size: 100% 100%;
}

.c-nav.-style-secondary ul li {
  font-family: "r", Arial, sans-serif;
  font-weight: normal;
}

.c-nav.-format-horizontal {
  width: 100%;
  display: flex;
}

.c-nav.-format-horizontal ul {
  width: 100%;
  flex-flow: row nowrap;
}

.c-nav.-format-horizontal ul li + li {
  margin-left: 2em;
}

.c-nav.-size-medium ul li a span {
  font-size: 1.125em;
  line-height: 0.88888889em;
  position: relative;
  margin: 0.5em 0 -0.44444444em;
  padding-bottom: 0.61111111em;
}

.c-nav.-size-medium ul li a span.-m1 {
  padding-bottom: 0.38888889em;
}

.c-nav.-size-medium ul li a span.-m2 {
  padding-bottom: 0.83333333em;
}

.c-nav.-size-medium ul li a span.-m3 {
  padding-bottom: 1.27777778em;
}

.c-nav.-size-medium ul li a span.-m0 {
  padding-bottom: -0.05555556em;
}

.c-nav.-size-medium ul li a span.-m0:after {
  height: 0;
}

.c-nav.-size-large ul li a span {
  font-size: 1.375em;
  line-height: 1.09090909em;
  position: relative;
  margin: 0.18181818em 0 -0.36363636em;
  padding-bottom: 0.72727273em;
}

.c-nav.-size-large ul li a span.-m1 {
  padding-bottom: 0.54545455em;
}

.c-nav.-size-large ul li a span.-m2 {
  padding-bottom: 0.90909091em;
}

.c-nav.-size-large ul li a span.-m3 {
  padding-bottom: 1.27272727em;
}

.c-nav.-size-large ul li a span.-m0 {
  padding-bottom: 0.18181818em;
}

.c-nav.-size-large ul li a span.-m0:after {
  height: 0;
}

.-t-dark .c-nav a {
  color: #ffffff;
}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */
/** Breakpoint:	Small ----------------------- */
@media (max-width: 48em) {
  .c-nav.-format-horizontal {
    width: auto;
    margin: 0 -2em;
    overflow: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-nav.-format-horizontal ul {
    width: auto;
    padding: 0 2em;
  }
}

.c-tag {
  font-size: 0.6875em;
  line-height: 1.81818182em;
  position: relative;
  margin: 0.63636364em 0 -0.72727273em;
  padding-bottom: 0.81818182em;
  padding: 0.36363636em 1.09090909em;
  margin: 0 0.36363636em 0 0;
  display: inline-block;
  color: #000000;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #F6F6FA;
  border-radius: 1.81818182em;
}

.c-tag.-m1 {
  padding-bottom: 0.81818182em;
}

.c-tag.-m2 {
  padding-bottom: 1.54545455em;
}

.c-tag.-m3 {
  padding-bottom: 2.27272727em;
}

.c-tag.-m0 {
  padding-bottom: 0.09090909em;
}

.c-tag.-m0:after {
  height: 0;
}

.c-tag.-style-white {
  color: #525d7e;
  background-color: #ffffff;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
}

.c-tag.-format-outline {
  position: relative;
  background-color: transparent;
}

.c-tag.-format-outline:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 1px solid currentColor;
  border-radius: 2.5em;
  opacity: 0.05;
}

.c-tag.-size-m {
  font-size: 0.9375em;
  line-height: 1.6em;
  position: relative;
  margin: 0.53333333em 0 -0.53333333em;
  padding-bottom: 0em;
  padding: 0.53333333em 1.6em;
  margin: 0 0.26666667em 0.53333333em 0;
  text-transform: none;
  letter-spacing: 0;
}

.c-tag.-size-l {
  font-size: 1.125em;
  line-height: 1.33333333em;
  position: relative;
  margin: 0.38888889em 0 -0.44444444em;
  padding-bottom: 0.05555556em;
  padding: 0.66666667em 1.55555556em;
  margin: 0 0.22222222em 0.44444444em 0;
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */
/** Breakpoint:	Large ----------------------- */
/** Breakpoint:	Small ----------------------- */
/**
 *	Core
 *	--------------------------------------------------
 */
html {
  min-height: 100%;
}

/**
 *	Base Containers
 *	--------------------------------------------------
 */
.outline-main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: row wrap;
}

main {
  width: 100%;
  position: relative;
  z-index: 0;
}

.view {
  min-height: 100%;
  box-sizing: border-box;
}

.view section {
  padding: 4em 0;
}

.view section.intro {
  min-height: 40em;
  display: flex;
  align-items: center;
}

/**
 *	Layouts
 *	--------------------------------------------------
 */
/**
 * 	Media Queries
 *	--------------------------------------------------
 */
/** Breakpoint:	large ----------------------- */
.v-landing section.intro {
  height: 65vh;
  margin-bottom: -8em;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  position: relative;
  justify-content: center;
}

.v-landing section.intro .c-button:hover {
  color: #FEE133;
}

.v-landing_logo {
  margin-bottom: auto;
}

.v-landing_3d {
  pointer-events: none;
}

.v-landing_3d-canvas canvas {
  height: 50em;
}

.v-landing .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  opacity: 0.75;
}

.v-landing .bg-video {
  width: 100%;
  position: absolute;
  top: -6em;
  left: 0;
  bottom: 0;
  z-index: 0;
}

.v-landing .bg-video:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background-image: radial-gradient(circle at 0.5px 0.5px, black 1px, transparent 0);
  background-size: 2px 2px;
  background-position: -19px;
}

.v-landing .bg-video:before {
  content: '';
  width: 100%;
  height: 40em;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-image: linear-gradient(0deg, black 0, transparent 100%);
}

.v-landing .bg-video video {
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 0;
  transform: translate3d(-50%, 0, 0);
  opacity: 0.5;
}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */
/** Breakpoint:	Large ----------------------- */
/** Breakpoint:	Small ----------------------- */
@media (max-width: 48em) {
  .v-landing_intro {
    width: auto;
  }
  .v-landing_logo {
    margin-bottom: 3em;
  }
  .v-landing_features {
    min-width: 0;
  }
}

/*# sourceMappingURL=main.css.map */