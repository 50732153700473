

//
//	Ozara / Components / Logo
//


.c-logo {
	margin: 0 auto;
	width: em(164);

}

.c-topgun-logo {
	margin: 0 auto;
	width: em(320);
	color: get-color( white );
}


/**
 * 	Media Queries
 *	--------------------------------------------------
 */

/** Breakpoint:	Large ----------------------- */

@include breakpoint( large ) {


}

/** Breakpoint:	Small ----------------------- */

@include breakpoint( small ) {

}
