

//
//	Ozara / UI / Tags
//


#{ $c-tag } {
	$size: 11;
	@include font-size( 11, 2.5, true, 1 );

	padding: lines(0.5, $size) lines(1.5, $size); margin: 0 lines(0.5, $size) 0 0;
	display: inline-block;
	color: get-color( base ); font-weight: normal;
	text-transform: uppercase; letter-spacing: 1px;
	background-color: get-color( grey-light );
	border-radius: lines(2.5, $size);

	// Styles

	&.-style-white {
		color: get-color( base-light );
		background-color: get-color( white );
		box-shadow: 0 2px 0 0 rgba(0,0,0,0.05);
	}

	// Formats

	&.-format-outline {
		position: relative;
		background-color: transparent;

		&:after {
			content: '';
			box-sizing: border-box;
			position: absolute; top: 0; left: 0; bottom: 0; right: 0;
			border: 1px solid currentColor; border-radius: lines(5);
			opacity: 0.05;
		}
	}


	// Sizes

	&.-size-m {
		$size: 15;
		@include font-size( $size, 3, false );
		padding: lines(1, $size) lines(3, $size); margin: 0 lines(0.5, $size) lines(1, $size) 0;
		text-transform: none; letter-spacing: 0;
	}

	&.-size-l {
		$size: 18;
		@include font-size( $size, 3, false );
		padding: lines(1.5, $size) lines(3.5, $size); margin: 0 lines(0.5, $size) lines(1, $size) 0;
		text-transform: none; letter-spacing: 0;
		font-weight: normal;
	}
}


/**
 * 	Media Queries
 *	--------------------------------------------------
 */

/** Breakpoint:	Large ----------------------- */

@include breakpoint( large ) {


}

/** Breakpoint:	Small ----------------------- */

@include breakpoint( small ) {

}
