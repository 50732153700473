

//
//	Ozara / Components / Form / Text
//


#{ $c-field-text } {
	input[disabled] {
		cursor: not-allowed !important;
	}

	&.-is-disabled {
		color: get-color( grey );
	}
}
