

//
//	Ozara / Components / Forms / Fields
//


#{ $c-field } {
	$c: &;
	margin-bottom: lines(2);
	display: block;
	cursor: pointer;

	&_icon {
		width: lines(7); height: lines(7);
		display: flex; align-items: center; justify-content: center;
		position: absolute; bottom: 0; right: 0;
		opacity: 0.5;
		pointer-events: none;
		transition: opacity 0.2s;
	}

	//	Field Label	 ----------------

	&_label {
		@include font-size( $label-font-size, 2, false );
		width: 100%;
		display: block; position: relative; z-index: 10;
		text-align: left; color: currentColor;
		-webkit-touch-callout: none;
		user-select: none;
		pointer-events: none;
		transform: translateY( 0 );
		transition: color 0.3s, opacity 0.3s, transform 0.3s;

		#{ $c-icon } {
			color: currentColor;
			opacity: 0;
			transition: opacity 0.3s;
		}
	}

	&_value {
		@extend %input-box;
		// height: lines(7);
		display: flex;
		flex: 1 1 0%;

		// &_text {
		// 	@extend %input-text;
		// 	height: lines( 7, $input-font-size );
		// 	white-space: nowrap;
		// }

		input {
		// 	width: 100%;
		// 	// width: 2px;
		// 	// display: inline-block;
		// 	// border-bottom: none;
		// 	// cursor: default;
		}
	}

	//	Field Message	 ------------

	&_message {
		padding: 0 lines(1);
		position: absolute; bottom: 0; left: 0; z-index: 50;
		text-align: left;
		display: inline-block;
		background-color: get-color( white );
		border-radius: 3px;
		box-shadow: 0 1px 2px 0 rgba(43,45,66,0.20), 0 4px 12px 0 rgba(43,45,66,0.10);
		transform: translateY( 100% );
		opacity: 0;
		pointer-events: none;
		transition: background-color 0.3s, color 0.3s, opacity 0.3s, transform 0.3s;

		&:before {
			content: '';
			width: 0; height: 0;
			position: absolute; top: -12px; left: lines(1);
			border: 6px solid transparent;
			border-bottom-color: get-color( white );
		}

		p {
			@include font-size( $label-font-size, 3, true, 0 );
			font-weight: bold;
		}
	}

	&_bg {
		width: 100%; height: 100%;
		position: absolute; top: 0; left: 0;
	}

	&:hover {
		#{ $c } {
			&_icon {
				opacity: 1;
			}
		}
	}

	//	States	 ----------------

	&.-is-empty {
		#{ $c } {
			&_label {
				opacity: 1;
				transform: translateY( 0 );
			}
		}

		select {
			font-weight: 300;
			color: fade-out( get-color( grey ), 0.25 );
		}
	}

	&.-is-inactive {
		#{ $c } {
			&_label {
				opacity: 0.5;
			}
		}
	}

	&.-is-valid {
		#{ $c } {
			&_label {
				#{ $c-icon } {
					color: get-color( brand );
					opacity: 1;
				}
			}
		}
	}

	&.-is-error {
		color: get-color( brand );

		input,
		select,
		textarea {
			border-bottom: 1px solid fade-out( get-color( brand ), 0.75 );
		}

		#{ $c } {
			&_label {
				#{ $c-icon } {
					color: get-color( brand );
					opacity: 1;
				}
			}

			&_message {
				color: get-color( white );
				background-color: get-color( brand );
				transform: translateY( 75% );
				opacity: 1;

				&:before {
					border-bottom-color: get-color( brand );
				}
			}
		}
	}
}
