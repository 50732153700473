

//
//	WeProspect / Components / Button
//


#{ $c-button } {
	$c: &;
	$lighten-amount: 5;

	margin: 0; padding: lines(2) lines(3); box-sizing: border-box;
	display: inline-flex; align-items: center; justify-content: center;
	/*font-family: $font-secondary;*/ color: get( white, $colors ); font-weight: bold;
	text-align: center; text-decoration: none;
	cursor: pointer;
	// background: linear-gradient(80deg, get-color( brand ) 40%, #00FFF0 130%);
	background-color: get-color( base-med );
	border: 0; border-radius: 0;
	box-shadow: 0 1px 2px 0 rgba(43,45,66,0.20), 0 4px 12px 0 rgba(43,45,66,0.10);
	transition: transform 0.2s ease-out, color 0.3s, background-color 0.3s, opacity 0.2s, box-shadow 0.2s;

	&_text {
		// font-size: font-scale( epsilon );
		line-height: lines(3);
		margin: 0 lines(1);
		white-space: nowrap;
	}

	&:before {
		width: 100%; height: 100%;
		position: absolute; z-index: 0;
		border: 4px solid fade-out( get-color( base ), 0.9 );
	}

	&:focus {
		outline:0;
	}

	&:hover {
		color: get( white, $colors );
		background-color: lighten( $button-color, $lighten-amount );
	}


	&:hover,
	&:visited,
	&:active {
		color: get( white, $colors );
		box-shadow: 0 2px 8px 0 rgba(43,45,66,0.20), 0 12px 32px 0 rgba(43,45,66,0.10);
	}


	//	Styles

	&.-style-none {
		padding: 0;
		color: inherit;
		background-color: transparent;
		box-shadow: none;
	}

	&.-style-outline {
		position: relative;
		color: currentColor;
		background: transparent;
		box-shadow: none;

		&:after {
			content: '';
			box-sizing: border-box;
			position: absolute; top: 0; right: 0; bottom: 0; left: 0;
			border: 1px solid currentColor;
			opacity: 0.1;
		}

		&:hover {
			color: get-color( brand );
		}
	}

	&.-style-error {
		background-color: get-color( error );

		&:hover {
			background-color: lighten( get-color( error ), $lighten-amount );
		}
	}


	//	Sizes
	&.-size-xsmall {
		padding: lines( 0.5 ) gutters( 0.25 );

		#{$c}_text {
			font-size: font-scale( f7 );
			line-height: lines( 3, f7 );
			margin: 0 lines( 0.5, f7 );
		}

		&.-style-outline {
			font-weight: normal;
		}
	}

	&.-size-small {
		padding: lines(1) lines(1.5);

		#{$c}_text {
			font-size: font-scale( f6 );
			line-height: lines(3);
			margin: 0 lines(0.75);
		}
	}

	&.-size-large {
		padding: lines(2.5) lines(4);
		border-radius: lines(6);

		#{$c}_text {
			font-size: font-scale( f5 );
		}
	}


	//	Formats

	&.-format-block {
		width: 100%;
		flex: 1 1 auto;
		justify-content: space-between;
	}

	&.-format-link {
		padding-left: 0; padding-right: 0;
		color: get-color( base );
		background-color: transparent;
		box-shadow: none;

		#{$c}_text {
			font-weight: normal;
			position: relative;
			overflow: hidden;
			// text-decoration: underline;

			&:after {
				content: '';
				width: 100%; height: 2px;
				position: absolute; bottom: 0; left: 0; z-index: 0;
				background-color: get-color( brand );
				transform: translate3d(-102%, 0, 0);
				transition: transform 0.3s ease;
				opacity: 1;
			}
		}

		&:hover {
			color: get-color( brand );

			#{$c}_text {

				&:after {
					transform: translate3d(0, 0, 0);
				}
			}
		}

		&.-size-xsmall {
			#{$c}_text {
				margin: 0 lines( 1, f7 );
			}
		}

		span:first-child {
			margin-left: 0 !important;
		}
	}

	&.-format-circle {
		width: lines(7);
		padding: lines(2) lines(1);

		&.-style-outline {
			&:after {
				border-width: 3px; border-radius: 50%;
			}
		}

		&.-size-small {
			width: lines( 5 );
			padding: lines(1.5) 0;
		}
	}

	&.-pos-right {
		margin-left: auto !important;
	}


	//	States

	&.-is-disabled {
		pointer-events: none !important;
		background-color: get-color( grey );

		#{$c}_text {
			opacity: 0.25;
		}
	}


	//	Themes

	//	Contexts

	#{ $c-button-group } & + & {
		margin-left: lines( 2 );

		&.-size-small {
			margin-left: lines( 1 );
		}

		&.-size-xsmall {
			margin-left: lines( 1 );
		}
	}

}


#{ $c-button-group }.-format-grouped {
	#{ $c-button } {
		border-radius: 0;

		&:after {
			border-radius: 0;
			border-right-width: 0;
		}

		+ #{ $c-button } {
			margin-left: 0;
		}

		&:first-child,
		&:first-child:after {
			border-top-left-radius: lines(4);
			border-bottom-left-radius: lines(4);
		}

		&:last-child,
		&:last-child:after {
			border-top-right-radius: lines(4);
			border-bottom-right-radius: lines(4);
		}

		&:last-child:after {
			border-right-width: 1px;
		}
	}
}


/**
 * 	Media Queries
 *	--------------------------------------------------
 */

@media ( hover: hover ) {
	#{ $c-button } {
		&:hover {
			transform: translateY(-1px);
		}
	}
}


/** Breakpoint:	Large ----------------------- */

@include breakpoint( large ) {


}

/** Breakpoint:	Small ----------------------- */

@include breakpoint( small ) {
	#{ $c-button } {
		#{ $c-button-group } & + & {
			margin-left: 0;

			&.-size-small {
				margin-left: 0;
			}

			&.-size-xsmall {
				margin-left: 0;
			}
		}
	}
}
