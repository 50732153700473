

//
//	WeProspect / Views / App
//


/**
 *	Core
 *	--------------------------------------------------
 */

html {
	min-height: 100%;
}
	body {

	}


/**
 *	Base Containers
 *	--------------------------------------------------
 */


.outline-main {
	width: 100%; min-height: 100vh;
	display: flex; flex-flow: row wrap;
}
	main {
		width: 100%;
		position: relative; z-index: 0;
	}
		.view {
			min-height: 100%;
			box-sizing: border-box;

			section {
				padding: lines(8) 0;

				&.intro {
					min-height: segments( 10 );
					display: flex; align-items: center;
				}


			}


		}

// .helper-grid {
// 	padding: 0 lines(10); box-sizing: border-box;
// }

/**
 *	Layouts
 *	--------------------------------------------------
 */


.view {

}


/**
 * 	Media Queries
 *	--------------------------------------------------
 */

/** Breakpoint:	large ----------------------- */
