

//
//	WeProspect / Components / Footer
//

$bg-color: get-color( white );
$dot-color: get-color( grey-light );
// $dot-color: lighten( get-color( base ), 10 );

// Dimensions
$dot-size: 2px;
$dot-space: 16px;

.c-footer {
	width: 100%;
	padding: lines(16) 0 segments(1); margin-top: auto;
	// color: get-color( white );
	background:
		linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
		linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
		$dot-color;
	background-size: $dot-space $dot-space;
	background-position: 0 0;

	h6 {
		color: get-color( grey );
	}

	// #{$c-logo} {
	// 	color: get-color( white );
	// }

}