

//
//	Ozara / Components / Header
//


.c-header {
	width: 90%; height: lines(12);
	margin: 0 auto;
	// position: absolute; top: 0; left: 0;
	position: relative; z-index: 10;
	display: flex; align-items: flex-end;
	color: get-color( white );
	background-color: get-color( brand );

	.c-nav {
		margin-top: lines(1);

		a {
			// color: get-color( base );

			&:hover {
				color: get-color( brand-yellow );
			}
		}
	}

	.c-button {
		&:hover {
			color: get-color( brand-yellow );
		}
	}
}
	.c-header_logo {
		width: em(164); height: em(40);
		margin: 0 auto lines(-1);
		display: block;
		color: get-color( white );
	}

	.c-header_contact {
		width: auto;
		display: flex; align-items: flex-end;
		flex: 0 1 auto;

		ul li {
			padding: 0 0 0 gutters(1);
			display: inline-block;
		}

		.divider {
			margin: 0 em(4);
			opacity: 0.5;
		}
	}

.c-header-nav {
	// width: lines(20);
	height: 0;
	position: fixed; top: 0; left: lines(10); right: lines(10); z-index: 10;
	// background-color: red;

	.o-icon {
		width: lines(4);

		svg {
			stroke: get-color( base );
			stroke-width: 2px;
		}
	}
}
	.c-header-nav_container {
		// width: 100vw;
		width: 100%; max-width: cols(15); height: 0;
		// position: absolute; top: 0; left: lines(10);
		position: relative;
		padding: 0 ; margin: 0 auto; box-sizing: border-box;
		// background-color: green;
	}
		.c-header-nav_bar {
			width: lines(14); height: 100vh;
			padding: lines(22) 0;
			position: absolute; top: 0; left: lines(-10); z-index: 20;
			display: flex;
			flex-flow: column wrap; align-items: center;
			// background-color: rgba(0,0,255,0.5);

			.menu-tag {
				transform: rotate(-90deg) translateX(-100%);
				font-size: 14px;
				opacity: 0.5;
			}
		}

		.c-header-nav_panel {
			width: 50%; min-width: cols(6); height: 100vh;
			padding: lines(20) 0 0 gutters(2); box-sizing: border-box;
			position: absolute; top: 0; left: 0; z-index: 10;
			display: flex; flex-flow: column nowrap;
			overflow: auto;
			// transform: translate3d( 0, lines(5), 0 );
			opacity: 0;
			pointer-events: none;
			transition: transform 0.5s ease, opacity 0.5s get-easing( ease-custom );

			nav {
				margin-bottom: lines(10);
				transition: transform 0.5s ease;

				ul {

				}
					li {
						opacity: 0;
						transform: translate3d(0,lines(4),0);
						transition: transform 1s, opacity 1s;
						will-change: transform, opacity;

					}
						a {
							@include font-size( beta );
							position: relative;
							font-family: $font-secondary;
							color: get-color( base );
							overflow: hidden;
							transition: color 0.2s;

							span {
								position: relative; z-index: 10;
							}

							&:after {
								content: '';
								width: 100%; height: lines(3, beta);
								position: absolute; top: 50%; left: 0; z-index: 0;
								background-color: get-color( brand );
								transform: translate3d(-100%, -50%, 0);
								transition: transform 0.3s ease;
							}

							&.-is-active,
							&:hover {
								// color: get-color( base-light );
								&:after {
									transform: translate3d(0, -50%, 0);
								}
							}

						}
			}

			.-is-menu-open & {
				transform: translate3d( 0, 0, 0 );
				opacity: 1;
				pointer-events: all;
				transition: transform 0.75s get-easing( ease-custom ), opacity 0.75s get-easing( ease-custom );

				nav {
					li {
						opacity: 1;
						transform: translate3d(0,0,0);

						&:nth-child(1) { transition-delay: 0s; }
						&:nth-child(2) { transition-delay: 0.1s; }
						&:nth-child(3) { transition-delay: 0.2s; }
						&:nth-child(4) { transition-delay: 0.3s; }
						&:nth-child(5) { transition-delay: 0.4s; }
						&:nth-child(6) { transition-delay: 0.5s; }
					}
				}
			}
		}
			.c-header-nav_panel_footer {
				margin: auto 0 lines(10);
			}

		.c-header-nav_overlay {
			width: 50%; height: 100vh; min-width: cols(6);
			background-color: get-color( white );
			position: absolute; top: 0; left: 0; z-index: 0;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.5s get-easing( ease-custom );

			.-is-menu-open & {
				opacity: 1;
			}
		}




@include breakpoint( large ) {
	.c-header-nav {
		left: lines(16); right: lines(16);
	}
		.c-header-nav_bar {
			width: lines(20);
			left: lines(-16);
		}
}