

//
//	WeProspect / Base / Typography
//


/*
 *	Headings
 *	--------------------------------------------------
 */


h1 {
	font-weight: bold;
	letter-spacing: -3px;
}
h2 {
	font-weight: bold;
	letter-spacing: -1px;
}
h3, h4 {
	// letter-spacing: -1px;
}
h3, h4, h5, h6 {
	font-family: $font-primary;
	font-weight: bold;
	color: inherit;
	color: get-color( base );

	// .-t-dark & {
	// 	color: get-color( white );
	// }
}
h6 {
	font-weight: bold;
	color: get-color( brand );
	// text-transform: uppercase; letter-spacing: 2px;
}

// p {
// 	@extend .zeta;
// }



ul, ol {
	list-style-type: none;
	padding-left: lines(3);

	li {
		font-weight: normal;
		// @include font-size( zeta, 3, true, 0 );
	}
}

.-style-gradient {
	background: linear-gradient(80deg, get-color( base ) 40%, get-color( brand ) 130%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

/**
 * 	Breakpoints
 *	--------------------------------------------------
 */


/** Breakpoint:	Desktop ----------------------- */

@include breakpoint( desktop ) {

	p {
		@include font-size( default );
	}



}

/** Breakpoint:	small ----------------------- */

@include breakpoint( small ) {

	h1, h2, h3 {
		letter-spacing: -1px;
	}

	.f1 { @include font-size( f2 ); }
	.f2 { @include font-size( f3 ); }
	.f4 { @include font-size( f5 ); }

}

